
const AsideMenuHeaderTitle = ({ title }: { title: string }) => {
    return (
        <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-gray-600 text-uppercase fs-8 ls-1'>{title}</span>
            </div>
        </div>
    )
}

export default AsideMenuHeaderTitle