// import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
// import * as signalR from '@microsoft/signalr';

// interface SignalRContextProps {
//     connection: signalR.HubConnection | null;
// }

// const SignalRContext = createContext<SignalRContextProps | undefined>(undefined);

// export const useSignalRHook = (): signalR.HubConnection | null => {
//     const context = useContext(SignalRContext);
//     if (!context) {
//         throw new Error('useSignalR must be used within a SignalRProvider');
//     }
//     return context.connection;
// };

// interface SignalRProviderProps {
//     token: string;
//     children: ReactNode;
// }

// export const SignalRProvider: React.FC<SignalRProviderProps> = ({ token, children }) => {
//     const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

//     useEffect(() => {
//         const connect = async () => {
//             const connection = new signalR.HubConnectionBuilder()
//                 .withUrl('https://apis.vachanfoods.in/mainHub', {
//                     accessTokenFactory: () => token,
//                     withCredentials: false
//                 })
//                 .withAutomaticReconnect()
//                 .build();

//             connection.on('ReceiveMessage', (user: string, message: string) => {
//                 console.log(`${user}: ${message}`);
//             });

//             try {
//                 await connection.start();
//                 console.log('SignalR Connected.');
//                 setConnection(connection);
//             } catch (err) {
//                 console.error('SignalR Connection Error:', err);
//             }

//             connection.onclose(async () => {
//                 await connect();
//             });
//         };

//         connect();

//         return () => {
//             if (connection) {
//                 connection.stop();
//             }
//         };
//     }, [token]);

//     return (
//         <SignalRContext.Provider value={{ connection }}>
//             {children}
//         </SignalRContext.Provider>
//     );
// };



import { createSignalRContext } from "react-signalr/signalr";
import useAuth from "../../modules/auth/hooks/useAuth";
import { BACKEND_BASE_URL } from "../api/api.constant";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const SignalRContext = createSignalRContext();

const SignalRProvider = ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated, currentUser } = useAuth()
    const { token: reduxToken } = useSelector((state: RootState) => state.auth)

    const token = isAuthenticated() || reduxToken
    return (
        <SignalRContext.Provider
            connectEnabled={!!token}
            accessTokenFactory={() => token || ''}
            dependencies={[token]} //remove previous connection and create a new connection if changed
            url={`${BACKEND_BASE_URL}/mainHub?user_id=${currentUser?.user_id}`}
            withCredentials={false}
        >
            {children}
        </SignalRContext.Provider>
    );
};

export default SignalRProvider;