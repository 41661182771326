
import { getToken, removeSession, setToken } from '../../utils/session'
export const TOKEN = '__r_a_token'
const getAuth = (): string | undefined => {
  const token: string | null = getToken(TOKEN)
  if (!token) {
    return
  }
  try {
    if (token) {
      // You can easily check token_token expiration also
      return token
    }
  } catch (error) {
    console.error('SESSION PARSE ERROR', error)
  }
}

const saveAuth = (token: string) => {

  try {
    setToken(TOKEN, token, { maxAge: 5 * 24 * 60 * 60 })
  } catch (error) {
    console.error('SESSION SAVE ERROR', error)
  }
}

const removeAuth = () => {
  try {
    removeSession(TOKEN)
  } catch (error) {
    console.error('SESSION REMOVE ERROR', error)
  }
}

const isAuthenticated = () => {
  const token: string | null = getToken(TOKEN)
  if (!token) {
    return
  }
  try {
    if (token) {
      return token
    }
  } catch (error) {
    console.error('SESSION PARSE ERROR', error)
    return
  }
}
export { getAuth, saveAuth, removeAuth, isAuthenticated }
