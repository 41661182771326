import { useEffect, useState } from 'react'
import { useLayout } from '../../../_metronic/layout/core'
import ReactSelect, { OptionProps, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useLocation } from 'react-router-dom'


// Custom Option component to highlight search text
export const HighlightedOption = ({ children, ...props }: OptionProps<any>) => {
    const { inputValue } = props.selectProps;
    const startIndex = children && children?.toString().toLowerCase().indexOf(inputValue.toLowerCase()) || 0;
    const endIndex = startIndex + inputValue.length;

    const highlightedText = (
        children && <>
            {children.toString().substring(0, startIndex)}
            <strong className='bg-warning'>{children.toString().substring(startIndex, endIndex)}</strong>
            {children.toString().substring(endIndex)}
        </>
    );

    return (
        <components.Option {...props}>
            {highlightedText}
        </components.Option>
    );
};

const SearchHeader = () => {
    const { pathname } = useLocation()
    const { headerSearchComponentProps, setHeaderSearchComponentProps } = useLayout();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selected: any, actionMeta?: any) => {
        // Do something with the selected option if needed
        if (headerSearchComponentProps?.onChange)
            headerSearchComponentProps?.onChange(selected, actionMeta)
        // Clear the selected option
        setSelectedOption(null);
    };

    useEffect(() => {
        return () => {
            if (setHeaderSearchComponentProps)
                setHeaderSearchComponentProps(undefined)
        }
    }, [pathname])

    if (!headerSearchComponentProps)
        return null
    return (
        <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            {headerSearchComponentProps.type === 'select' ? <ReactSelect styles={{
                input(base, props) {
                    return { ...base, width: 200, padding: '0.6rem 0rem' }
                },
                control(base, props) {
                    return { ...base, borderRadius: '0.475rem', borderColor: '#DBDFE9' }
                },
                menu: (provided) => ({
                    ...provided,
                    height: 400, // Set your desired height here
                }),
                menuList: (provided) => ({
                    ...provided,
                    maxHeight: 400, // Set your desired height here
                }),
            }}
                {...headerSearchComponentProps}
                components={{ Option: HighlightedOption }}
                value={selectedOption}
                onChange={handleChange} /> :
                <AsyncSelect
                    styles={{
                        input(base, props) {
                            return { ...base, width: '45vw', minWidth: 200, padding: '0.6rem 0rem' }
                        },
                        control(base, props) {
                            return { ...base, borderRadius: '0.475rem', borderColor: '#DBDFE9' }
                        },
                        menu: (provided) => ({
                            ...provided,
                            height: 400, // Set your desired height here
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            maxHeight: 400, // Set your desired height here
                        }),
                    }}  {...headerSearchComponentProps}
                    components={{ Option: HighlightedOption }}
                    value={selectedOption}
                    onChange={handleChange} />
            }
        </div>
    )
}

export default SearchHeader