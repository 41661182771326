import { useEffect } from 'react';
import { useLayout } from '../../../_metronic/layout/core';

const SplashScreen = () => {
    const { enableSplashScreen, disableSplashScreen } = useLayout()
    useEffect(() => {
        const timer = setTimeout(() => {
            enableSplashScreen();
        }, 200)
        return () => {
            clearTimeout(timer)
            disableSplashScreen();
        }
    }, [])
    return (
        null
    )
}

export default SplashScreen