import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Swal, { SweetAlertIcon } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { selectAppNotification } from '../../app.slice'

const SweetAlert = () => {
    const { title, msg, type = 'danger' } = useSelector(selectAppNotification)

    useEffect(() => {
        withReactContent(Swal).fire({
            title: title && <span className={`fw-bolder `}>{title}</span>,
            html: <h4 className='mt-2'><div dangerouslySetInnerHTML={{ __html: msg || 'No information' }}></div></h4>,
            icon: type === 'danger' ? 'error' : type as SweetAlertIcon,
            heightAuto: false,
        })
    }, [])
    return (
        <div></div>
    )
}

export default SweetAlert