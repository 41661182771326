import { IFieldErrorText } from "./_models";

const FieldErrorText = ({ error }: IFieldErrorText) => {

  return (

    <div className='fv-plugins-message-container text-danger'>
      <div className='fv-help-block'>
        <span role='alert'>{error}</span>
      </div>
    </div>
  );
}

export default FieldErrorText;
