
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { KTCard, toAbsoluteUrl } from '../../../_metronic/helpers'
import { StyledBackground } from './components/StyledBackground'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <StyledBackground>
      <div className='d-flex flex-column flex-lg-row-reverse flex-column-fluid h-100'>
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-center justify-content-center w-lg-50 p-10 order-2 order-lg-1'>
          {/* begin::Form */}
          <KTCard className='d-flex flex-lg-center flex-column w-lg-500px"'>
            <div >
              {/* begin::Wrapper */}
              <div className='w-lg-600px p-10'>
                <Outlet />
              </div>
              {/* end::Wrapper */}
            </div>
          </KTCard>
          {/* end::Form */}

          {/* begin::Footer */}
          {/* <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div> */}
          {/* end::Footer */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            <Link to='/' className='mb-12'>
              <img alt='Logo' style={{ height: 100 }} src={toAbsoluteUrl(import.meta.env.VITE_APP_LOGO_AUTH)} />
            </Link>
            {/* end::Logo */}

            {/* begin::Image */}

            {/* end::Image */}

            {/* begin::Title */}
            <h1 className=' fs-2qx fw-bolder text-secondary text-center mb-7'>
              {import.meta.env.VITE_APP_NAME}
            </h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fs-base text-center'>
              {import.meta.env.VITE_APP_DESCRIPTION}

            </div>
            {/* end::Text */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}
      </div>
    </StyledBackground>

  )
}

export { AuthLayout }
