import { useEffect, } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { appNotification, selectAppNotification } from '../../app.slice'
import useBreakpoint from '../../hooks/useBreakpoint'
import './Snackbar.css'
import CustomModal from '../CustomModal/CustomModal'
import { useModal } from '../../hooks/useModal'
import { IIConType, KTIcon } from '../../../_metronic/helpers'
import { TIConName } from '../../../_metronic/helpers/icons-config/icons'
import SweetAlert from './SweetAlert'

const icon: { [x: string]: { color: string, name: TIConName, type: IIConType } } = {
    'success': { color: '#50cd89', name: 'check-circle', type: 'solid' },
    'info': { color: '#7239ea', name: 'information-5', type: 'duotone' },
    'danger': { color: '#f1416c', name: 'information', type: 'duotone' },
    'error': { color: 'red', name: 'information', type: 'duotone' },
    'white': { color: '#000000', name: 'information-3', type: 'duotone' },
}
const ToastWrapper = () => {
    const { title, msg, type = 'danger', notificationType } = useSelector(selectAppNotification)
    const { sm } = useBreakpoint()
    const dispatch = useDispatch()
    const { open, onOpen, onClose } = useModal()
    useEffect(() => {
        if (msg) {
            onOpen()
            const timer = setTimeout(() => {
                dispatch(appNotification({ title: '', msg: undefined }))
                onClose()
            }, 2500)
            return () => {
                clearTimeout(timer)
                onClose()
            }
        }
    }, [msg])

    if (notificationType === 'modal')
        return <CustomModal title={title || 'General info'} centered open={open} handleOnClose={onClose} closeModal={onClose} noFooter={true} style={{ zIndex: 1056 }}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <KTIcon iconName={icon?.[type].name} iconStyle={{ color: icon?.[type].color }} iconType={icon?.[type].type} size={72} />
                <h4 className='mt-2'>{msg || 'No information'}</h4>
            </div>
        </CustomModal>
    if (sm)
        return !!msg && <div className="snackbar">{msg}</div>
    if (notificationType === 'alert')
        return (
            <ToastContainer
                className="p-3"
                position={'top-end'}
                style={{ zIndex: 1057, position: 'fixed' }}
            >
                <Toast show={!!msg} style={{ position: 'relative', top: 100 }}>
                    <Toast.Header className={`bg-light-${type}`}>
                        <strong className={`me-auto`}>{title}</strong>
                    </Toast.Header>
                    <Toast.Body className={`bg-light-${type}`}>
                        <span>{msg}</span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        )
    return !!msg && <SweetAlert />

}

export default ToastWrapper