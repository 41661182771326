import clsx from 'clsx';
import { ForwardedRef, InputHTMLAttributes, MouseEvent, forwardRef, useState } from 'react'
import IconButton from '../IconButton/IconButton';

export type TInputProps = {
    error?: string;
    touched?: boolean;
    inputType?: 'solid' | 'outline'

} & InputHTMLAttributes<HTMLInputElement>

const PasswordInput = forwardRef(({ touched, error, inputType = 'solid', ...props }: TInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [show, setShow] = useState(false)
    const togglePasswordVisibility = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()
        console.log('ca');

        setShow(!show)

    }
    return (
        <div className={`input-group input-group-${inputType}`}>
            <input
                ref={ref}
                type={show ? 'text' : 'password'}
                className={clsx(
                    `form-control  px-3 py-1 ${props?.className || 'form-control-transparent'}`,
                    { 'is-invalid': touched && error },
                    {
                        'is-valid': touched && !error,
                    }
                )}
                autoComplete='off'
                {...props}
            />
            <span className="input-group-text px-3 py-1" >
                <IconButton icon='eye' type='button' iconSize={18} onClick={togglePasswordVisibility} />
            </span>
        </div>
    )
})

export default PasswordInput