import { ReactNode, FC, CSSProperties } from "react";
import { Button, Modal, ModalProps, Spinner } from "react-bootstrap";
import IconButton from "../IconButton/IconButton";
import styles from './CustomModal.module.css'
interface CustomModalProps extends ModalProps {
  title?: string;
  titleStyle?: CSSProperties;
  titleClass?: string;
  open: boolean;
  openModal?: () => void;
  setOpen?: (value: boolean) => void;
  closeModal?: () => void;
  children: ReactNode;
  handleOnClose?: (node: HTMLElement) => any;
  cancelButtonText?: string;
  submitButtonText?: string;
  renderFooterButtons?: ReactNode;
  noFooter?: boolean;
  onSubmit?: (data?: any) => void;
  disableSubmit?: boolean;
  onCancel?: () => void;
  noCloseIcon?: boolean;
  fullscreen?: string | true;
  isLoading?: boolean;
  loadingText?: string;
  width?: string;
  bodyStyle?: CSSProperties;
  bodyClassName?: string;
  noCancelButton?: boolean
  // Add other props if necessary
}

const CustomModal: FC<CustomModalProps> = ({
  title,
  titleStyle = {},
  titleClass = '',
  open,
  openModal,
  setOpen,
  closeModal,
  children,
  handleOnClose,
  cancelButtonText,
  submitButtonText,
  renderFooterButtons,
  noFooter = false,
  onSubmit,
  disableSubmit = false,
  onCancel,
  noCloseIcon = false,
  fullscreen,
  isLoading = false,
  width = '700px',
  loadingText = 'Saving...',
  bodyClassName = '',
  noCancelButton = false,
  ...props
}: CustomModalProps) => {
  const handleSubmit = () => {
    // if (closeModal) closeModal();
    if (onSubmit) onSubmit();
  };

  return (
    <Modal
      show={open}
      fullscreen={fullscreen || 'sm-down'}
      onExit={handleOnClose}
      animation={true}
      onHide={closeModal}
      backdropClassName={styles.backdrop}
      dialogClassName={`${fullscreen ? `w-lg-100 ${styles.customModalDialogFullscreen}` : `${width === '700px' ? styles.customModalDialog : `min-w-${width}`}`} `}
      contentClassName={`w-lg-${fullscreen ? '100' : ''}`}
      {...props}
    >
      {isLoading && <div className={styles.loader}>
        <Spinner animation="border" />
        <span>{loadingText}</span>
      </div>}
      <Modal.Header>
        {title && <h2 className={`fw-bolder ${titleClass} `} style={titleStyle}>{title}</h2>}
        {!noCloseIcon && (
          <IconButton icon="cross" className="bg-primary rounded-circle" iconClassName="text-white" iconType="outline" onClick={closeModal} />
        )}
      </Modal.Header>
      <Modal.Body style={{ ...props?.bodyStyle, }} className={bodyClassName || ''}>{children}</Modal.Body>
      {!noFooter &&
        (renderFooterButtons ? (
          <Modal.Footer>{renderFooterButtons}</Modal.Footer>
        ) : (
          <Modal.Footer>
            {!noCancelButton && <Button className="btn btn-secondary btn-sm btn-light me-3" onClick={closeModal}>{cancelButtonText || "Cancel"}</Button>}
            <Button className="btn btn-primary btn-sm" onClick={handleSubmit} disabled={disableSubmit}>
              {submitButtonText || "Submit"}
            </Button>
          </Modal.Footer>
        ))}
    </Modal>
  );
};

export default CustomModal;
