import SplashScreen from "../SplashScreen/SplashScreen"

const PageLoader = () => {
    return (
        <div>
            <SplashScreen />
        </div>
    )
}

export default PageLoader