import React, { CSSProperties } from 'react'
import icons, { TIConName } from '../icons-config/icons'
import { getLayout } from '../../layout/core'

export type IIConType = 'duotone' | 'solid' | 'outline';
type Props = {
  className?: string
  iconType?: IIConType
  iconName: TIConName;
  size?: number | string;
  iconStyle?: CSSProperties
}

const KTIcon: React.FC<Props> = ({ className = '', iconType, iconName, size, iconStyle = {} }) => {
  if (!iconType) {
    iconType = getLayout().main?.iconType
  }

  return (
    <i style={{ fontSize: size, ...iconStyle }} className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}>
      {iconType === 'duotone' &&
        [...Array(icons[iconName])].map((e, i) => {
          return (
            <span
              key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1}`}
            ></span>
          )
        })}
    </i>
  )
}

export { KTIcon }
