import { useState } from "react";
type ModalProps = {
  handleClose?: () => void
}
export const useModal = (props?: ModalProps) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    props?.handleClose && props?.handleClose();
    setOpen(false);
  };
  const onOpen = () => setOpen(true);
  return {
    open,
    setOpen,
    onClose,
    onOpen,
  };
};
