import { useState, useEffect } from "react";

interface NetworkConnectionInfo {
    rtt?: number;
    type?: string;
    saveData?: boolean;
    downLink?: number;
    downLinkMax?: number;
    effectiveType?: string;
}
interface NetworkInformation extends NetworkConnectionInfo {
    downlink?: number;
    downlinkMax?: number
}
interface NetworkState extends NetworkConnectionInfo {
    online: boolean;
    since?: string;
}

function getNetworkConnection(): NetworkInformation | null {
    return (
        // @ts-ignore
        navigator.connection ||
        // @ts-ignore
        navigator.mozConnection ||
        // @ts-ignore
        navigator.webkitConnection ||
        null
    );
}

function getNetworkConnectionInfo(connection: NetworkInformation | null): NetworkConnectionInfo {
    if (!connection) return {};

    return {
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downLink: connection.downlink,
        downLinkMax: connection.downlinkMax,
        effectiveType: connection.effectiveType,
    };
}

function useNetwork(): NetworkState {
    const [state, setState] = useState<NetworkState>(() => ({
        since: undefined,
        online: navigator.onLine,
        ...getNetworkConnectionInfo(getNetworkConnection()),
    }));

    useEffect(() => {
        const handleOnline = () => {
            setState((prevState) => ({
                ...prevState,
                online: true,
                since: new Date().toISOString(),
            }));
        };

        const handleOffline = () => {
            setState((prevState) => ({
                ...prevState,
                online: false,
                since: new Date().toISOString(),
            }));
        };

        const handleConnectionChange = () => {
            setState((prevState) => ({
                ...prevState,
                ...getNetworkConnectionInfo(getNetworkConnection()),
            }));
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        const connection = getNetworkConnection();
        // @ts-ignore
        connection?.addEventListener("change", handleConnectionChange);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
            // @ts-ignore
            connection?.removeEventListener("change", handleConnectionChange);
        };
    }, []);

    return state;
}

export default useNetwork;
