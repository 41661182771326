import { appNotification } from '../../../app.slice';
import { IResponse } from '../../../network/network.interfaces';
import { api } from '../../../services/api/api'
import { BackendAuthApi } from '../../../services/api/api.constant';
import { User } from '../../users/models/Users.interfaces';

export const PROFILE_API = `/users`;
export const CHANGE_PASSWORD_API = `/change-password`;

export const profileApi = api
    .injectEndpoints({
        endpoints: builder => ({
            changePassword: builder.mutation<IResponse<any>, any>({
                query: (payload) => {
                    const formData = payload;
                    //    uncoment below for  multipart formdata
                    // formData = new FormData();
                    // Object.keys(payload).map(f => formData.append(f, payload?.[f]))
                    return ({
                        method: "POST",
                        url: `${BackendAuthApi}/changePassword`,
                        body: formData,
                        // formdata: true, uncomment for multipart formdata
                    })
                },
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status)
                            dispatch(appNotification({ title: 'Saved', type: 'success', msg: data?.message || 'Password changed successfully', notificationType: 'modal' }))
                        else
                            dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Saving!', }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Saving!', }))
                    }
                },
                invalidatesTags: (result, error, arg) => [{ type: 'Auth', id: arg.token }]
            }),


        })
    })

export const {
    useChangePasswordMutation,
} = profileApi
