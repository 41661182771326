import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import formValidationRules from '../../../utils/formValidationRules';
import { useChangePasswordMutation } from '../services/profile.api';
import FieldErrorText from '../../../components/FormBuilder/FieldErrorText';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../modules/auth/features/auth.slice';
import { KTIcon } from '../../../../_metronic/helpers';
import IconButton from '../../../components/IconButton/IconButton';
import PasswordInput from '../../../components/PasswordInput/PasswordInput';

interface IChangePasswordForm { current_password: string; new_password: string; confirm_password: string }

const schema = Joi.object({
    current_password: formValidationRules.password.label('Current Password'),
    new_password: formValidationRules.password.label('New Password'),
    confirm_password: formValidationRules.confirmPassword.label('Confirm Password'),
})


const ChangePassword = (
    {
        onClose,
        afterSubmit,
        user_id
    }: {

        onClose?: () => void;
        afterSubmit?: () => void;
        user_id?: string;
    }
) => {
    const { handleSubmit, register, formState: { errors } } = useForm<IChangePasswordForm>({
        resolver: joiResolver(schema),
    })
    const [changePasswordSubmit, { data, isLoading, isSuccess, }] = useChangePasswordMutation()
    const currentUser = useSelector(selectCurrentUser)
    const onSubmit = (data: IChangePasswordForm) => {
        changePasswordSubmit({ ...data, user_id: user_id || currentUser?.user_id }).finally(() => {
            if (onClose)
                onClose()
            if (afterSubmit)
                afterSubmit()
        })
    }

    return (
        <form>
            <div className='d-flex flex-column'>

                <div className='mb-7'>
                    <div className='mb-3'>
                        <PasswordInput {...register('current_password')} placeholder='Current Password' />
                        {errors.current_password?.message && <FieldErrorText error={errors.current_password?.message} />}

                    </div>
                    <div className='mb-3'>
                        <PasswordInput {...register('new_password')} placeholder='New Password' />
                        {errors.new_password?.message && <FieldErrorText error={errors.new_password?.message} />}
                    </div>
                    <div className="mb-3">
                        <PasswordInput {...register('confirm_password')} placeholder='Confirm Password' />
                        {errors.confirm_password?.message && <FieldErrorText error={errors.confirm_password?.message} />}
                    </div>
                </div>

            </div>
            <br />
            <div className="d-flex justify-content-end">
                {onClose && <button className="btn btn-flat" onClick={onClose}>Cancel</button>}
                <button className="btn btn-primary" type="submit" disabled={isLoading} onClick={handleSubmit(onSubmit)}>{isLoading ? `Changing...` : `Change Password`}</button>
            </div>
        </form>
    )
}

export default ChangePassword