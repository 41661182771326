
export const WALLET_REASON_TYPE_OPTIONS = [
    { label: 'Credit', value: 'Credit' },
    { label: 'Debit', value: 'Debit' },
]

export const WALLET_REASON_TRANSACTION_TYPE_OPTIONS = [
    { label: 'Adjustment', value: 'Adjustment' },
    { label: 'Recharge', value: 'Recharge' },
]
export const WALLET_REASON_TYPE_OPTIONS_MAP: { [x: string]: { label: string; value: string } } = {
    Credit: { label: 'Credit', value: 'Credit' },
    Debit: { label: 'Debit', value: 'Debit' },
}

export const WALLET_REASON_TRANSACTION_TYPE_OPTIONS_MAP: { [x: string]: { label: string; value: string } } = {
    Adjustment: { label: 'Adjustment', value: 'Adjustment' },
    Recharge: { label: 'Recharge', value: 'Recharge' },
}
export const CRON_JOB_TIME = { hour: 17, min: 29 }