import { ButtonHTMLAttributes, HTMLAttributes, ReactNode } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { TIConName } from '../../../_metronic/helpers/icons-config/icons';

interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: TIConName | ReactNode | JSX.Element;
    iconType?: 'duotone' | 'solid' | 'outline'
    iconClassName?: string;
    iconStyle?: React.CSSProperties;
    iconSize?: number;
}

// using typeguard of typescript
const isTIConName = (icon: TIConName | ReactNode): icon is TIConName => {
    return typeof icon === 'string';
};

const IconButton = ({ icon, iconType, iconClassName, className, iconSize, iconStyle, ...props }: IIconButtonProps) => {
    return (
        <button
            className={`btn btn-icon btn-sm btn-active-icon-primary ${className || ''}`}
            style={{ cursor: 'pointer' }}
            {...props}
        >
            {isTIConName(icon) ? (
                <KTIcon
                    iconName={icon}
                    iconType={iconType}
                    size={iconSize}
                    iconStyle={iconStyle}
                    className={`${!iconSize ? 'fs-1' : ''} ${iconClassName || ''}`}
                />
            ) : (
                icon
            )}
        </button>
    )
}

export default IconButton