// import { useEffect, useState } from "react";
// import { useSignalRHook } from "./SignalRProvider";
// import { HubConnection } from "@microsoft/signalr";
// interface Message {
//     user: string;
//     message: string;
// }

import { SignalRContext } from "./SignalRProvider";


// export default function useSignalR({ onReceiveMessage, receiverEvent }: { receiverEvent: string; onReceiveMessage: (user: string, message: string) => void }) {
//     const connection = useSignalRHook();
//     // const [message, setMessage] = useState<string>('');
//     useEffect(() => {
//         if (!connection) return;
//         connection.on(receiverEvent, onReceiveMessage);

//         return () => {
//             connection.off(receiverEvent, onReceiveMessage);
//         };
//     }, [connection]);

//     const sendMessage = async ({ eventName, message, connection }: { eventName: string; message: string; connection: HubConnection | null }) => {
//         if (message && connection) {
//             await connection.invoke(eventName, message);
//         }
//     };

//     return { connection, sendMessage }

// }

export default function useSignalR() {
    return SignalRContext
}