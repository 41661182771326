import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

const useBreakpoint = () => {
    const xs = useMediaQuery("(min-width: 0px) and (max-width: 480px)");
    const sm = useMediaQuery("(min-width: 0px) and (max-width: 599px)");
    const md = useMediaQuery("(min-width: 600px) and (max-width: 767px)");
    const lg = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const xl = useMediaQuery("(min-width: 1024px)");
    const xxl = useMediaQuery("(min-width: 1240px)");
    return { xs, sm, md, lg, xl, xxl };
};

export default useBreakpoint;
