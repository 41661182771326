import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { RootState } from '../../../store/store'
import { getToken } from "../../utils/session";
import { TOKEN } from "../../modules/auth/AuthHelper";
import { deAuthUser, logoutUser } from "../../modules/auth/features/auth.slice";
import { useNavigate } from "react-router-dom";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = (getState() as RootState)?.auth.token || getToken(TOKEN)
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
        try {
            // const navigate = useNavigate();

            // try to get a new token
            // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
            // if (refreshResult.data) {
            //   // store the new token
            //   api.dispatch(tokenReceived(refreshResult.data))
            //   // retry the initial query
            //   result = await baseQuery(args, api, extraOptions)
            // } else {
            // navigate('/logout')
            api.dispatch(deAuthUser(true))
            // }
        } catch (error) {
            console.log(error);

        }

    }
    return result
}

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 1 })

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded 
 */
export const api = createApi({
    /**
     * `reducerPath` is optional and will not be required by most users.
     * This is useful if you have multiple API definitions,
     * e.g. where each has a different domain, with no interaction between endpoints.
     * Otherwise, a single API definition should be used in order to support tag invalidation,
     * among other features
     */
    reducerPath: 'api',
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithRetry,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: ['Auth', 'Modules', 'Module_Path', 'Navigation', 'Customers'],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
})


