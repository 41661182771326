import '../sentry.ts'
import { createRoot } from 'react-dom/client'
// Axios
// import axios from 'axios'
// import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { MetronicSplashScreenProvider } from './_metronic/layout/core'
import { DynamicRouteProvider } from './app/routing/DynamicRouteProvider'
import SignalRProvider from './app/services/signalR/SignalRProvider'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    // <PersistGate persistor={persistor}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <MetronicSplashScreenProvider>
            <DynamicRouteProvider>
              {/* <AuthProvider> */}
              <SignalRProvider>
                <AppRoutes />
              </SignalRProvider>
              {/* <AppRoute /> */}
              {/* </AuthProvider> */}
            </DynamicRouteProvider>
          </MetronicSplashScreenProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>

    </Provider>
    // </PersistGate>

  )
}
