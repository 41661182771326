export const DEFAULT_ACTION = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    READ: 'read',
    LIST: 'list',
}

export const DEFAULT_ACTION_TYPE = {
    INLINE: 'inline',
    GLOBAL: 'global'
}

export const defaultActionArray = [{
    title: DEFAULT_ACTION.CREATE,
    key: DEFAULT_ACTION.CREATE,
    value: true,
    type: 'global',
    isDefault: true
},
{
    title: DEFAULT_ACTION.READ,
    key: DEFAULT_ACTION.READ,
    value: true,
    type: 'inline',
    isDefault: true

},
{
    title: DEFAULT_ACTION.UPDATE,
    key: DEFAULT_ACTION.UPDATE,
    value: true,
    type: 'inline',
    isDefault: true

},
{
    title: DEFAULT_ACTION.DELETE,
    key: DEFAULT_ACTION.DELETE,
    value: true,
    type: 'inline',
    isDefault: true
}
]