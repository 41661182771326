import { Suspense, useCallback, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { ThemeModeProvider } from '../_metronic/partials'
import ToastWrapper from './components/Toast/Toast'
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from 'react-redux'
import { QueryStatus } from '@reduxjs/toolkit/query'
import PageLoader from './components/PageLoader/PageLoader'
import { RootState } from '../store/store'
import useAuth from './modules/auth/hooks/useAuth'
import { APP_ROUTES, AUTH_ROUTES, PUBLIC_ROUTES, } from './routing/routes'
import useSignalR from './services/signalR/useSignalR'
import { useDispatch } from 'react-redux'
import { appNotification,  } from './app.slice'
import { signalRNotificationEvent } from './services/signalR/signalREvent'
import { ISignalREventPayload } from './app.interfaces'
import useNetwork from './hooks/useNetwork'
import { isDeAuthenticated } from './modules/auth/features/auth.slice'

const App = () => {
  const { online } = useNetwork()
  const { currentUser, logout } = useAuth()
  const { connection } = useSignalR()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => {
    const skipApis = ['getAdminDashboardStats', 'respondToTicket'];
    return Object.values({ ...state.api.mutations, ...state.api.queries }).some((query) => {
      if (query && skipApis.includes(query?.endpointName as string))
        return false
      return query && query.status === QueryStatus.pending;
    });
  });

  const isDeAuth = useSelector(isDeAuthenticated);
  const onReceiveMessage = useCallback((payload: ISignalREventPayload) => (msg: string) => {
    const { callback, ...rest } = payload
    if (callback)
      callback(rest)
    dispatch(appNotification({ ...rest, msg }))

  }, [dispatch])

  const reloadPage = () => window.location.reload();

  useEffect(() => {
    if (!connection) return
    Object.keys(signalRNotificationEvent).forEach(key => {
      let s: ISignalREventPayload = signalRNotificationEvent[key];
      if (key === 'logoutUser')
        s = {
          ...s, callback: () => {
            navigate(APP_ROUTES.LOGOUT)
          }
        }
      connection.on(key, onReceiveMessage(s));
    })
    return () => Object.keys(signalRNotificationEvent).forEach(key => {
      connection.off(key, onReceiveMessage(signalRNotificationEvent[key]));
    })
  }, [connection])

  useEffect(() => {
    if (isDeAuth) {
      navigate(APP_ROUTES.LOGOUT)
    }
  }, [isDeAuth])

  return (
    <ErrorBoundary fallback={<div className='w-100 h-100 d-flex flex-center'>
      <div className='card'>
        <div className='card-body d-flex flex-column flex-center'>
          <h4>Oops something went wrong. If problem not resolved then please reload page</h4>
          <br />
          <button className='btn btn-primary' onClick={reloadPage}>Reload</button>
        </div>
      </div>
    </div>}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          {!online && <div className='d-flex flex-center bg-danger' style={{ zIndex: 10000 }}>
            <span className='fw-bolder text-white'>You are Offline! Please check your Internet connection</span>
          </div>}
          <LayoutProvider>
            <ThemeModeProvider>
              {isLoading && <PageLoader />}
              <Outlet />

              <MasterInit />
              <ToastWrapper />
            </ThemeModeProvider>
          </LayoutProvider>

        </I18nProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export { App }
