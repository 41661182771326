import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { AppState, IAppNotificationPayload, ILocalNotification, ISidebarCounter } from "./app.interfaces";

const INITIAL_STATE: AppState = {
    appNotification: {
        title: '',
        msg: undefined,
        type: 'white',
        notificationType: 'toast',
    },
    localNotification: [],
    sidebarCounter: undefined
}
const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        appNotification: (state: AppState, { payload }: { payload: IAppNotificationPayload }) => {
            state.appNotification = { ...payload }
        },
        localNotification: (state: AppState, { payload }: { payload: ILocalNotification }) => {
            state.localNotification = [{ ...payload }, ...state.localNotification]
        },
        updateSidebarCounter: (state: AppState, { payload }: { payload: ISidebarCounter }) => {
            state.sidebarCounter = { ...payload }
        },
    }
})

export const { appNotification, localNotification, updateSidebarCounter } = appSlice.actions
export const selectAppNotification = (state: RootState) => state.app.appNotification;
export const selectSidebarCounter = (state: RootState) => state.app.sidebarCounter;
export const selectLocalNotification = (state: RootState) => state.app.localNotification;
export const unReadLocalNotificationCount = (state: RootState) => state.app.localNotification.map(v => !v.isRead);

export default appSlice.reducer