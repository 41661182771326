import { createSelector, createSlice } from '@reduxjs/toolkit'
import { customersApi } from '../services/customers.api'
import { RootState } from '../../../../store/store'
import { ICustomerState } from '../models/Customers.interfaces'




const initialState: ICustomerState = {
    subcriptionCart: undefined,
    buyOnceCart: undefined,
    customer_id: '',
    customer_name: ''
}

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        saveSubcriptionToCart: (state: ICustomerState, { payload }) => {
            state.subcriptionCart = {
                ...state.subcriptionCart,
                [payload?.customer_id]: { ...state?.subcriptionCart?.[payload?.customer_id], [payload?.package_id]: payload?.item },
            }
        },
        removeSubcriptionToCart: (state: ICustomerState, { payload: { package_id, customer_id } }) => {
            // @ts-ignore
            if (state.subcriptionCart?.[customer_id]?.[package_id]) {
                // @ts-ignore
                delete state.subcriptionCart?.[customer_id]?.[package_id];
                if (state.subcriptionCart?.[customer_id] && Object.keys(state.subcriptionCart?.[customer_id])?.length === 0)
                    delete state.subcriptionCart?.[customer_id];
                state.subcriptionCart = state.subcriptionCart
            }
        },
        clearSubcriptionToCart: (state: ICustomerState, { payload: { customer_id } }) => {
            const newData: any = { ...state.subcriptionCart };
            delete newData?.[customer_id];
            state.subcriptionCart = newData
        },
        saveBuyOnceToCart: (state: ICustomerState, { payload }) => {
            state.buyOnceCart = {
                ...state.buyOnceCart,
                [payload?.customer_id]: { ...state?.buyOnceCart?.[payload?.customer_id], [payload?.package_id]: payload?.item },
            }
        },
        removeBuyOnceToCart: (state: ICustomerState, { payload: { package_id, customer_id } }) => {
            // @ts-ignore
            if (state.buyOnceCart?.[customer_id]?.[package_id]) {
                // @ts-ignore
                delete state.buyOnceCart?.[customer_id]?.[package_id];
                if (state.buyOnceCart?.[customer_id] && Object.keys(state.buyOnceCart?.[customer_id])?.length === 0)
                    delete state.buyOnceCart?.[customer_id];
                state.buyOnceCart = state.buyOnceCart
            }
        },
        clearBuyOnceToCart: (state: ICustomerState, { payload: { customer_id } }) => {
            const newData: any = { ...state.buyOnceCart };
            delete newData?.[customer_id];
            state.buyOnceCart = newData
        }
    },
    extraReducers: (builder) => { },
})


export const { saveSubcriptionToCart, removeSubcriptionToCart, clearSubcriptionToCart, saveBuyOnceToCart, removeBuyOnceToCart, clearBuyOnceToCart } = customersSlice.actions
export const customerState = (state: RootState) => state.customers
export const selectSubcriptionCart = ({ customer_id }: { customer_id: number }) => createSelector([customerState], (customers) => customers?.subcriptionCart?.[customer_id])
export const selectSubcriptionCartCount = ({ customer_id }: { customer_id: number }) => createSelector([selectSubcriptionCart({ customer_id })], (cart: any) => cart ? Object.keys(cart)?.length : 0)
export const selectBuyOnceCart = ({ customer_id }: { customer_id: number }) => createSelector([customerState], (customers) => customers?.buyOnceCart?.[customer_id])
export const selectBuyOnceCartCount = ({ customer_id }: { customer_id: number }) => createSelector([selectBuyOnceCart({ customer_id })], (cart: any) => cart ? Object.keys(cart)?.length : 0)

export default customersSlice.reducer